
/* font-weight: 300 */
@font-face {
  font-family: 'Poppins-Light';
  src: url('./Poppins/Poppins-Light.ttf');
  font-display: fallback;
}

/* font-weight: 400 */
@font-face {
  font-family: 'Poppins-Regular';
  src: url('./Poppins/Poppins-Regular.ttf');
  font-display: fallback;
}

/* font-weight: 500 */
@font-face {
  font-family: 'Poppins-Medium';
  src: url('./Poppins/Poppins-Medium.ttf');
  font-display: fallback;
}

/* font-weight: 600 */
@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./Poppins/Poppins-SemiBold.ttf');
  font-display: fallback;
}
