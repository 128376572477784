@import './assets/fonts';
@import './styles';

html, body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  font-size: 16px;
  font-family: 'Poppins-Regular', Helvetica, Arial, sans-serif;
  color: #FFF;
  background: $standard-background;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
